<span
  class="blued show-checklist fa fa-file-invoice"
  #t="ngbTooltip"
  [class.no-checklist]="!documentAiChecklist"
  [ngbTooltip]="docAiHtml"
  [autoClose]="false"
  (click)="t.open()"
  triggers="manual"
  tooltipClass="tooltip-container"
  placement="bottom"
  container="body"
></span>

<ng-template #docAiHtml>
  <div class="doc-ai-checklist-popover">
    <button class="close-button" (click)="t.close()">X</button>

    <span class="blued fa fa-file-invoice"></span><b class="blued">DocAI Checklist</b>
    <!-- Display the classification, quality, and validations in the same list -->
    <div *ngFor="let checklist of documentAiChecklist">
      <b class="doc-title" *ngIf="checklist.docTitle">{{ checklist.docTitle }}</b>
      <ul>
        <!-- Quality -->
        <li *ngIf="checklist.quality">
          {{ checklist.quality.name }}:
          <span [ngClass]="checklist.quality.value">
            {{ checklist.quality.value | titlecase }}
          </span>
        </li>

        <!-- Classification -->
        <li *ngIf="checklist.classification">
          {{ checklist.classification.name }}:
          <span [ngClass]="checklist.classification.value">
            {{ checklist.classification.value | titlecase }}
          </span>
        </li>

        <!-- Validations -->
        <ng-container *ngIf="checklist.validation">
          <li *ngFor="let validation of checklist.validation">
            {{ validation.name }}:
            <span [ngClass]="validation.value">
              {{ validation.value | titlecase }}
            </span>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>
</ng-template>
